module.exports = {
  pushToHash: (asPath, extraPadding = 98) => {
    const hash = asPath.split("#")[1];

    if (hash) {
      setTimeout(() => {
        const elements = document.getElementsByTagName("section");
        for (const element in elements) {
          if (elements[element] instanceof HTMLElement) {
            if (elements[element].getAttribute(`data-attr-scroll`) === hash) {
              const anchor = elements[element];
              const destinationOffset =
                anchor.getBoundingClientRect().top +
                window.scrollY -
                extraPadding;
              window.scrollTo(0, destinationOffset);
              elements[element].focus();
            }
          }
        }
      }, 600);
    }
  },
};
