import classNames from "classnames";
import get from "lodash/get";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React from "react";

import ScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./HomepageHero.module.scss";

const HeroText = dynamic(() => import("./HeroText"));
const BackgroundRenderer = dynamic(
  () => import("@/components/BackgroundRenderer")
);
const StickyHeader = dynamic(() => import("@/components/StickyHeader"));
const VideoPlayerRenderer = dynamic(
  () => import("@/components/VideoPlayerRenderer")
);
const PageAnchorNavigation = dynamic(
  () => import("@/components/page-anchor-navigation")
);
const UserInteractionRenderer = dynamic(
  () => import("./UserInteractionRenderer")
);

const HomepageHero = ({
  hero,
  isArticle,
  isDesktop,
  isMobile,
  isTablet,
  moduleList,
  primaryCtaStyles,
  type,
}) => {
  const {
    background,
    backgroundLoopVideo,
    backgroundMobile,
    hasBackgroundShade = false,
    headline,
    headlineAlignment = "Left",
    headlineDesktop,
    headlineDesktopSize,
    headlineMobile,
    headlineMobileSize,
    isCompact = false,
    showNearbyLocations,
    stickyHeader,
    subcopy,
    subheading,
    userInteraction,
    video,
    videoMobile,
  } = hero.fields;

  const TYPE =
    type || hero?.fields?.userInteraction?.[0]?.sys?.contentType?.sys?.id;

  const isHeadlineAlignmentSet = !!(
    headlineAlignment !== null && headlineAlignment !== "Default"
  );

  const videoAsset = get(video, "[0].fields.videoFile.fields.file", null);
  const videoMobileAsset = get(
    videoMobile,
    "[0].fields.videoFile.fields.file",
    null
  );

  let justifyContent = "justify-content-start";

  switch (headlineAlignment) {
    case "Right":
      justifyContent = "justify-content-end";
      break;
    case "Left":
      justifyContent = "justify-content-start";
      break;
    case "Center":
      justifyContent = "justify-content-center";
      break;

    default:
      break;
  }

  let overrideStyles;

  let innerStyle;

  return (
    <section
      className={classNames(
        !headline && styles.homePageHero,
        headline && styles.pageHero
      )}
      data-compact={isCompact}
      data-is="HomepageHero"
    >
      {headline && (
        <HeroText
          className={styles.heroText}
          hasBackgroundShade={hasBackgroundShade}
          headline={headline}
          isCompact={isCompact}
          subheading={subheading}
          type={TYPE}
          userInteraction={userInteraction}
        />
      )}

      {!headline && (
        <div className={styles.heroContents} style={{ ...overrideStyles }}>
          <div className={styles.heroInner} style={{ ...innerStyle }}>
            {/*
             * If isHeadlineAlignmentSet is set to a value not default or null
             * this will override the legacy values and give position control
             * to the content owner
             */}
            {isHeadlineAlignmentSet && (
              <div className={`${styles.heroInnerText} ${justifyContent}`}>
                <HeroText
                  headlineAlignment={headlineAlignment}
                  headlineDesktop={headlineDesktop}
                  headlineDesktopSize={headlineDesktopSize}
                  headlineMobile={headlineMobile}
                  headlineMobileSize={headlineMobileSize}
                  primaryCtaStyles={primaryCtaStyles}
                  styles={styles}
                  subcopy={subcopy}
                  type={TYPE}
                  userInteraction={userInteraction}
                />
              </div>
            )}

            {/*
             * If isHeadlineAlignmentSet is set to value default or null
             * the legacy features will be turned on
             */}
            {showNearbyLocations ||
              (TYPE && !isHeadlineAlignmentSet && (
                <UserInteractionRenderer
                  headlineDesktop={headlineDesktop}
                  headlineDesktopSize={headlineDesktopSize}
                  primaryCtaStyles={primaryCtaStyles}
                  showNearbyLocations={showNearbyLocations}
                  styles={styles}
                  subcopy={subcopy}
                  type={TYPE}
                  userInteraction={userInteraction}
                  userInteractionStyle={styles.userInteractionStyle}
                />
              ))}

            {moduleList && (
              <div className={styles.subNav}>
                <PageAnchorNavigation
                  fixed={false}
                  label="Navigation"
                  moduleList={moduleList}
                />
              </div>
            )}

            {stickyHeader && (
              <StickyHeader data={stickyHeader.fields} isTransparent={true} />
            )}
          </div>
        </div>
      )}

      <div
        className={classNames(
          styles.backgroundMediaContainer,
          isArticle && styles.backgroundArticleContainer,
          headline && styles.backgroundMedia
        )}
      >
        {!videoAsset && background && (
          <div
            className={`d-lg-inline-block d-sm-none d-xs-none ${styles.backgroundImage}`}
          >
            <BackgroundRenderer
              background={background}
              backgroundLoopVideo={backgroundLoopVideo}
              isMobile={isMobile}
            />
          </div>
        )}

        {!videoMobileAsset && backgroundMobile && (
          <div
            className={`d-md-inline-block d-lg-none d-xl-none ${styles.backgroundImage}`}
          >
            <BackgroundRenderer
              background={backgroundMobile}
              backgroundLoopVideo={backgroundLoopVideo}
              isMobile={isMobile}
            />
          </div>
        )}

        {video && videoMobile && (
          <VideoPlayerRenderer
            isDesktop={isDesktop}
            isMobile={isMobile}
            isTablet={isTablet}
            video={video}
            videoMobile={videoMobile}
          />
        )}
      </div>
    </section>
  );
};

HomepageHero.propTypes = {
  hero: PropTypes.object.isRequired,
  isArticle: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  moduleList: PropTypes.array,
  primaryCtaStyles: PropTypes.string,
  type: PropTypes.string,
};

HomepageHero.defaultProps = {
  primaryCtaStyles: "",
};

export default ScreenDimensions(HomepageHero);
