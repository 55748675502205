import logger from "../../../utils/logger";
import ApiClient from "../client";

const Subscription = {
  namespace: "subscription",

  basePath: "/api/subscription",

  async sendSubscription({
    firstName,
    lastName,
    emailAddress,
    activationCode,
  }) {
    const endpoint = `${this.basePath}`;
    const data = {
      activationCode,
      emailAddress,
      firstName,
      lastName,
    };

    try {
      const resp = await ApiClient._post(`${endpoint}`, {
        baseURL: ApiClient.config().app_host,
        data,
      });

      if (!resp.data) {
        logger.warn(`[subscription api] -> WARNING no data returned`);
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.warn(
        `[Subscription] -> sendSubscription | error encountered: ${error}`
      );
    }
  },
};

export default Subscription;
