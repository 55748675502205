import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import SubscriptionApiClient from "@/api/client/entities/subscription";
import LinkArrow from "@/assets/icons/link-arrow-2.svg";
import FormattedHeadline from "@/components/FormattedHeadline";
import Loader from "@/components/Loader";
import { EqxForm, EqxTextInput } from "@/features/eqx-form";
import { MailingListFlowSchema } from "@/utils/helpers/validations";

import styles from "./MailingListForm.module.scss";

const MailingListForm = ({
  activationCode,
  buttonText,
  headline,
  legalText,
  successfulMessage,
}) => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailSubmitting, setIsEmailSubmitting] = useState(false);

  const onSubmitForm = async (values) => {
    try {
      setIsEmailSubmitting(true);
      setIsLoading(true);

      const resp = await SubscriptionApiClient.sendSubscription({
        ...values,
        activationCode,
      });

      if (resp) {
        setSuccessfullySubmitted(true);
        formRef?.current?.resetForm();
        setIsFormValid(false);

        if (window._satellite) {
          window._satellite.track("activationcode", {
            activationcode: `${activationCode}`,
          });
        }
      } else {
        setIsErrorMsg(true);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <section
      className={styles.mailingListForm}
      data-is="MailingListForm"
      id="mailing-list-form"
    >
      {isLoading && <Loader hasBackgroundShade isOverlay />}

      <span className={styles.content}>
        <h3 className={styles.headline}>
          {successfullySubmitted ? (
            <FormattedHeadline richText={successfulMessage} />
          ) : (
            <FormattedHeadline richText={headline} />
          )}
        </h3>

        <EqxForm
          className={styles.form}
          onSubmit={onSubmitForm}
          onValidityChange={(isValid) => {
            setIsFormValid(isValid);
          }}
          ref={formRef}
          schema={MailingListFlowSchema}
        >
          <EqxTextInput
            className={styles.firstNameField}
            fieldName="firstName"
            label="First name"
          />

          <EqxTextInput
            className={styles.lastNameField}
            fieldName="lastName"
            label="Last name"
          />

          <div className={styles.emailAddressField}>
            <button
              className={styles.submitButton}
              disabled={!isFormValid || isEmailSubmitting}
              name="MailingListForm - Submit"
              type="submit"
            >
              {buttonText}
              <LinkArrow aria-hidden={true} />
            </button>

            <EqxTextInput
              fieldName="emailAddress"
              label="Email address"
              type="email"
            />
          </div>

          <blockquote className={styles.statusText} data-has-error={isErrorMsg}>
            {isErrorMsg
              ? "*An error has occurred. Please try again."
              : legalText}
          </blockquote>
        </EqxForm>
      </span>
    </section>
  );
};

MailingListForm.defaultProps = {
  activationCode: null,
};

MailingListForm.propTypes = {
  activationCode: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  headline: PropTypes.object.isRequired,
  legalText: PropTypes.string.isRequired,
  successfulMessage: PropTypes.object.isRequired,
};

export default MailingListForm;
