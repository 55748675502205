import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { UserContext } from "@/context/User";
import Utilities from "@/utils/helpers/formatting";

const useAcdlGlobalPageTracking = (
  globalTrackingFn,
  pageTitle,
  pageCategory,
  adobeAnalytics
) => {
  const { getDataLayerInfo, initialized } = useContext(UserContext);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const [acdlPageLoadFlag, setAcdlPageLoadFlag] = useState(false);
  const { asPath } = useRouter();

  useEffect(() => {
    if (initialized && !acdlPageLoadFlag) {
      setAcdlPageLoadFlag(true);

      let acdlEvent = {
        ...globalTrackingFn(
          asPath,
          pageTitle,
          pageCategory ? pageCategory : ""
        ),
        user: getDataLayerInfo(),
      };

      if (adobeAnalytics && adobeAnalytics.fields) {
        acdlEvent = Utilities.toACDLDataLayerFormat(acdlEvent, adobeAnalytics);
      }

      setNewDataLayer(acdlEvent);
    }
  }, [initialized]); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2321
};

export default useAcdlGlobalPageTracking;
