import PropTypes from "prop-types";
import React from "react";

import uiMapper from "@/components/mapper";

const DynamicRenderer = (props) => {
  const Component = uiMapper[props.fileName];

  if (
    Component &&
    (typeof Component === "function" ||
      Object.prototype.hasOwnProperty.call(Component, "render"))
  ) {
    return <Component {...props} />;
  }

  console.error(`Component not found: ${props.fileName}`, typeof Component);
  return <div data-component-name={props.fileName}>Component not found.</div>;
};

DynamicRenderer.propTypes = {
  club: PropTypes.exact({
    fields: PropTypes.object.isRequired,
    metadata: PropTypes.object,
    sys: PropTypes.object.isRequired,
  }),
  data: PropTypes.object,
  fileName: PropTypes.string.isRequired,
};

export default DynamicRenderer;
